/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Helmet from "react-helmet"

import "../styles/base.scss"
import "../styles/archiveOrg.scss"

// export function setHorses() {
//   return horses.pop()
// }

// let allHorses = horseThemAround(useStables())
// let displayHorse = horses.pop()
// const [horse, setHorses] = React.useState(displayHorse)

// if candidate horses is undefined or an empty array, then candidate horses = horseemarond()
// so then on the first time it runs, it will initilize, and it will fix it if it's empty

// suck the last horse right out of candidates using pop
// if (updateHorses) {
//   horse = setHorses()
// }

// console.log(`Single ${JSON.stringify(horse)}`)
// console.log(JSON.stringify(horses))

const Layout = ({ children, bodyClass, hideHeader }) => {
  const data = graphql`
    query query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: bodyClass,
        }}
      />
      {!hideHeader ? (
        <header>
          <Link to="/">← Back to the stable</Link>
        </header>
      ) : null}
      <main>{children}</main>
      {hideHeader ? (
        <footer
          style={{
            marginTop: `2rem`,
            textAlign: `center`,
            fontSize: `12px`,
          }}
        >
          Made with ❤︎ by
          {` `}
          <a href="https://twitter.com/diana_perkins">Diana</a>
        </footer>
      ) : null}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
